import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShopService } from 'src/app/services/shop.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { RoutingService } from '../../services/routing.service';

@Component({
  selector: 'app-restaurant-content',
  templateUrl: './restaurant-content.component.html',
  styleUrls: ['./restaurant-content.component.scss']
})
export class RestaurantContentComponent implements OnInit, OnDestroy {

  // LAAX (restaurantId 2), has different menu than others
  menuItems = [
    {title: 'ORDER', action: 'delivery', exceptRestaurant: [2], onlyRestaurant: []}, // not in 2
    {title: 'TAKEOUT', action: 'takeout', exceptRestaurant: [2], onlyRestaurant: []},
    // {title: 'TAKEOUT', action: 'app-marketing', exceptRestaurant: [], onlyRestaurant: [2]},
    /* {title: 'LUNCH_MENU', action: 'menu', exceptRestaurant: [2, 1, 4], onlyRestaurant: []}, */
    {title: 'MENU', action: 'wp-menu', exceptRestaurant: [], onlyRestaurant: []},
    {title: 'TABLE_RESERVATION', action: 'table-reservation', exceptRestaurant: [], onlyRestaurant: []}, // not in 2
    {title: "VOUCHERS", action: 'voucher', exceptRestaurant: [2], onlyRestaurant: [] },
    {title: 'GALLERY', action: 'gallery', exceptRestaurant: [], onlyRestaurant: []},
  ];
  selectedMenuItem = null;
  orderType$: any;
  col1$: any;
  currentRestaurantId = null;

  constructor(
              private shopService: ShopService,
              private nav: NavigationService,
              private router: Router,
              private route: ActivatedRoute,
              private angulartics2: Angulartics2
  ) { }

  ngOnInit() {
    this.orderType$ = this.shopService.orderType.subscribe((o) => {
      if (o === 'takeout' || o === 'delivery') {
        this.selectedMenuItem = this.menuItems.filter((m) => m.action === o)[0];
      }
    });

    this.col1$ = this.nav.col1.subscribe((c) => {
      const col1 = c;
      if (col1 === 'menu' || col1 === 'table-reservation' || col1 === 'gallery') {
        this.selectedMenuItem = this.menuItems.filter((m) => m.action === col1)[0];
      }
    });
    this.angulartics2.eventTrack.next({ action: 'selectRestaurant' });
    this.currentRestaurantId = this.shopService.restaurant.value.restaurantId;
  }

  ngOnDestroy() {
    this.orderType$.unsubscribe();
    this.col1$.unsubscribe();
  }

  onItemClick(menuItem) {
    this.selectedMenuItem = menuItem;
    const r = this.shopService.restaurant.value.restaurantId;
    const q = JSON.parse(JSON.stringify(this.nav.shopQueryParams.value));
    q.restaurantId = r;
    q.col2 = null;
    this.nav.col2.next(null);
    if (menuItem.action === 'delivery') {
      /* this.angulartics2.eventTrack.next({ action: 'selectDelivery' });
      this.shopService.orderType.next(menuItem.action);
      this.nav.col1.next('address');
      q.orderType = menuItem.action;
      q.col1 = 'address';
      this.nav.shopQueryParams.next(q);
      this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix)); */
      this.angulartics2.eventTrack.next({ action: 'selectDelivery' });
      this.nav.col1.next(null);
      q.orderType = null;
      window.open('https://www.ubereats.com/ch/brand/nooba', '_blank');
    }
    if (menuItem.action === 'takeout') {
      this.angulartics2.eventTrack.next({ action: 'selectTakeout' });
      this.shopService.orderType.next(menuItem.action);
      this.nav.col1.next('categories');
      q.orderType = menuItem.action;
      q.col1 = 'categories';
      this.nav.shopQueryParams.next(q);
      this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix));
    }
    if (menuItem.action === 'menu' ) {
      this.angulartics2.eventTrack.next({ action: 'selectLunchMenu' });
      this.nav.col1.next('menu');
      q.orderType = null;
      q.col1 = 'menu';
      this.nav.shopQueryParams.next(q);
      this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix));
    }
    if (menuItem.action === 'table-reservation' ) {
      this.angulartics2.eventTrack.next({ action: 'selectTableReservation' });
      this.nav.col1.next('table-reservation');
      q.orderType = null;
      q.col1 = 'table-reservation';
      this.nav.shopQueryParams.next(q);
      this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix));
    }
    if (menuItem.action === 'gallery' ) {
      this.angulartics2.eventTrack.next({ action: 'selectGallery' });
      this.nav.col1.next('gallery');
      q.orderType = null;
      q.col1 = 'gallery';
      this.nav.shopQueryParams.next(q);
      this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix));
    }
    if (menuItem.action === 'wp-menu' ) {
      this.angulartics2.eventTrack.next({ action: 'selectMenuWP' });
      this.nav.col1.next('wp-menu');
      q.orderType = null;
      q.col1 = 'wp-menu';
      this.nav.shopQueryParams.next(q);
      this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix));
    }
    if (menuItem.action === 'app-marketing' ) {
      this.angulartics2.eventTrack.next({ action: 'selectTakeoutAppMarketing' });
      this.nav.col1.next('app-marketing');
      q.orderType = null;
      q.col1 = 'app-marketing';
      this.nav.shopQueryParams.next(q);
      this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix));
    }
    if (menuItem.action === 'pdf-menu' ) {
      this.angulartics2.eventTrack.next({ action: 'selectMenuPDF' });
      this.nav.col1.next(null);
      q.orderType = null;
      const rn = this.shopService.restaurant.value.name;
      window.open('http://www.nooba.ch/pdf-menu/' + rn.replace(/\s+/g, '-').toLowerCase() + '-menu.pdf', '_blank');
    }
    if (menuItem.action === 'voucher' ) {
      this.angulartics2.eventTrack.next({ action: 'voucher' });
      this.nav.col1.next(null);
      q.orderType = null;
      window.open('https://weindepot.ch/produkt/nooba-gutscheinkarte/', '_blank');
    }
  }

  getMenuItems() {
    const id = this.shopService.restaurant.value.restaurantId;
      const items = [];
      this.menuItems.forEach(
        (i) => {
          if (i.onlyRestaurant.length < 1) {
            let except = false;
            i.exceptRestaurant.forEach(e => {
              if (e === id) {
                except = true;
              }
            });
            if (!except) {
              items.push(i);
            }
          } else {
            i.onlyRestaurant.forEach(o => {
              if (o === id) {
                items.push(i);
              }
            });
          }

        }
      );
      return items;
  }
}
