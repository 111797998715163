import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { AuthComponent } from './user/auth/auth.component';
import { ProfileComponent } from './user/profile/profile.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ShopComponent } from './shop/shop.component';
import { RestaurantsComponent } from './shop/restaurants/restaurants.component';
import { OrderComponent } from './shop/order/order.component';
import { DeliveryAddressComponent } from './shop/order/delivery-address/delivery-address.component';
import { CategoriesComponent } from './shop/order/categories/categories.component';
import { BasketComponent } from './shop/basket/basket.component';
import { BasketItemsComponent } from './shop/basket/basket-items/basket-items.component';
import { AddressComponent } from './address/address.component';
import { MenuComponent } from './shop/menu/menu.component';
import { GalleryComponent } from './shop/gallery/gallery.component';
import { FooterComponent } from './footer/footer.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { FoldItemComponent } from './fold-item/fold-item.component';
import { JobsComponent } from './jobs/jobs.component';
import { ImprintComponent } from './imprint/imprint.component';
import { HelpComponent } from './user/help/help.component';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH';
import localeEnGb from '@angular/common/locales/en-GB';
import { CurrencyNumberPipe } from './pipes/currency-number.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BasketNavigationComponent } from './basket-navigation/basket-navigation.component';
import { ApiService } from './services/api.service';
import { NavigationService } from './services/navigation.service';
import { ShopService } from './services/shop.service';
import { RestaurantContentComponent } from './fold-item/restaurant-content/restaurant-content.component';
import { TableReservationComponent } from './shop/table-reservation/table-reservation.component';
import { CategoryContentComponent } from './fold-item/category-content/category-content.component';
import { HttpRequestInterceptor } from './http-request.interceptor';
import { AuthService } from './services/auth.service';
import { ErrorService } from './services/error.service';
import { FormsModule } from '@angular/forms';
import { TimeContentComponent } from './fold-item/time-content/time-content.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { LoginComponent } from './user/login/login.component';
import { RegistrationComponent } from './user/registration/registration.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { MatDialogModule } from '@angular/material/dialog';

// material stuff
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule, MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReplaceLineBreaksPipe } from './pipes/replace-line-breaks.pipe';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { AutoFocusDirective } from './auto-focus.directive';
import { Angulartics2Module } from 'angulartics2';
import { AppMarketingComponent } from './shop/app-marketing/app-marketing.component';
import { WpMenuComponent } from './shop/wp-menu/wp-menu.component';
import { SuccessComponent } from './shop/success/success.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { CustomDateAdapter } from './custom-datepicker-adapter';
import { I18nModule } from './i18n.module';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { NutritionComponent } from './nutrition/nutrition.component';
import { DiscoverpanasiaComponent } from './shop/discoverpanasia/discoverpanasia.component';
import { InitiativesComponent } from './shop/initiatives/initiatives.component';
import { OrderCompletedComponent } from './shop/order-completed/order-completed.component';
import { SafePipe } from './pipes/safe.pipe';
import { AgbComponent } from './agb/agb.component';
import { PrivacyComponent } from './privacy/privacy.component';


registerLocaleData(localeDeCh, 'de');
registerLocaleData(localeEnGb, 'en');

// AoT requires an exported function for factories
// export function HttpLoaderFactory(httpClient: HttpClient) {
//   return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
// }

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    AuthComponent,
    ProfileComponent,
    HelpComponent,
    NavigationComponent,
    ShopComponent,
    RestaurantsComponent,
    OrderComponent,
    DeliveryAddressComponent,
    CategoriesComponent,
    BasketComponent,
    BasketItemsComponent,
    AddressComponent,
    MenuComponent,
    GalleryComponent,
    FooterComponent,
    CreditCardComponent,
    FoldItemComponent,
    JobsComponent,
    ImprintComponent,
    CurrencyNumberPipe,
    BasketNavigationComponent,
    RestaurantContentComponent,
    TableReservationComponent,
    CategoryContentComponent,
    TimeContentComponent,
    LocalizedDatePipe,
    LoginComponent,
    RegistrationComponent,
    ChangePasswordComponent,
    ReplaceLineBreaksPipe,
    ResetPasswordComponent,
    AutoFocusDirective,
    AppMarketingComponent,
    WpMenuComponent,
    SuccessComponent,
    ProductDialogComponent,
    NutritionComponent,
    DiscoverpanasiaComponent,
    InitiativesComponent,
    OrderCompletedComponent,
    SafePipe,
    AgbComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'ngx-nooba-shop'
    }),
    I18nModule,
    HttpClientModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatRippleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    FormsModule,
    MatDialogModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB2Sjiw7RjVNP1HDgS3cQgGvEvnfF4jBKk',
      libraries: ['places']
    }),
    DeviceDetectorModule.forRoot(),
    Angulartics2Module.forRoot(),
    CommonModule,
    TransferHttpCacheModule,
  ],
  providers: [
    ApiService,
    AuthService,
    ErrorService,
    NavigationService,
    ShopService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'de-CH' },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
    Angulartics2GoogleGlobalSiteTag,
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ProductDialogComponent
  ]
})
export class AppModule { }
