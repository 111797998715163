import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ShopService } from 'src/app/services/shop.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-reservation',
  templateUrl: './table-reservation.component.html',
  styleUrls: ['./table-reservation.component.scss']
})
export class TableReservationComponent implements OnInit, OnDestroy {

  iframeLoaded = false;

  iframeSrc: any;
  restaurant$: any;
  restaurant: any;
  loading = true;
  isKreuzplatz = false;
  isEuropaallee = false;
  isWinterthur = false;
  isLaax = false;
  trustedHTMLEuropa = null;
  isSet = false;

  merchantUrl = '';

  @ViewChild('iframe') iframe: ElementRef;
  // @ViewChild('quandoo-booking-widget') divView: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private shop: ShopService,
    private elementRef: ElementRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.restaurant$ = this.shop.restaurant.subscribe((res) => {
      if (res) {
        if (res.restaurantId) {
          const r = this.shop.restaurant.value.restaurantId;
          // EUROPAALLEE
          if (r === 3) {
            this.isEuropaallee = true;
            this.isKreuzplatz = false;
            this.isLaax = false;
            this.isWinterthur = false;
          }
          if (r === 2) {
            this.isLaax = true;
            this.isKreuzplatz = false;
            this.isEuropaallee = false;
            this.isWinterthur = false;
          }
          // KREUZPLATZ
          if (r === 1) {
            this.isKreuzplatz = true;
            this.isEuropaallee = false;
            this.isLaax = false;
            this.isWinterthur = false;
          }
          if (r === 4) {
            this.isWinterthur = true;
            this.isKreuzplatz = false;
            this.isEuropaallee = false;
            this.isLaax = false;
          }
        }
      }
    });
  }

  bypassSecurity(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    console.log('test')
    if (this.isEuropaallee) {
      this.merchantUrl = 'https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiOTVqNjJmUXNEWVNTTk1ObVgiLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9'
      this.iframeLoaded = true;
      this.loading = false;
    } else if (this.isKreuzplatz){
      this.merchantUrl = 'https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiak5uNkNBWHU4ZGNSZFBiU3QiLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9'
      this.iframeLoaded = true;
      this.loading = false;
    } else if (this.isWinterthur){
      this.merchantUrl = 'https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiWGJMRlNaeG9FZk00a2N6RTkiLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9'
      this.iframeLoaded = true;
      this.loading = false;
    } else if (this.isLaax){
      this.merchantUrl = 'https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiTkxLRHN6OWVLNnVzUU5yR1ciLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9'
      this.iframeLoaded = true;
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.restaurant$.unsubscribe();
    // document.getElementById('quandoo-booking-widget').remove();
  }
}
